import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Forgot from './pages/forgot';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */

// import { LocalNotifications } from '@capacitor/local-notifications'
// import { CapacitorStorage } from '../src/entities/Storage';

// async function requestPermissions() {
//   await LocalNotifications.requestPermissions()
// }

// async function TriggerNotification() {
//   const localStorage = new CapacitorStorage()
//   const { date } = await localStorage.get('date')
//   await LocalNotifications.schedule({ 
//     notifications: [
//       {
//         title: 'Yaz Helper',
//         body: 'O Horário de tomar a Pílula Chegou!',
//         id: 1,
//         schedule: {
//           at: new Date(date),
//           repeats: true,
//           every: 'day',
//           allowWhileIdle: true
//         },
//         smallIcon: 'icon',
//         largeIcon: 'icon_large',
//         iconColor: "#FFFFFF"
//       }
//     ]
//   })
// }

// TriggerNotification();
// requestPermissions();
const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/" exact={true}>
          <Redirect to="/home" />
        </Route>
        <Route path="/home" exact={true}>
          <Home />
        </Route>
        <Route path="/esqueci-de-tomar/">
           <Forgot />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
