import React, { useEffect, useState } from 'react';
import {
  IonModal
  , IonButton
  , IonText
  , IonDatetime
  , IonIcon
  , IonToast
  , useIonToast
  , IonListHeader,
  IonLabel
} from '@ionic/react';
import {
  IonItem
} from '@ionic/react';


// Images 
import arrow from '../../assets/icon/arrow-left-to-right.png'

import { calendar } from 'ionicons/icons';
// Sheets
import './modal.css'
import { CapacitorStorage } from '../../entities/Storage';
import moment from 'moment';

const initialState = { date: '' }
const ModalComponent = ({ show, setShowModal, pillDate }: any) => {
  const [input, setInput] = useState(initialState)
  const [firstAccess, setFirstAccess] = useState(false)
  const [initNewPack, setInitNewPack] = useState(false)
  const [packDate, setPackDate] = useState('')
  const onInputChange = (key: string, value: string) => setInput(prev => ({ ...prev, [key]: new Date(new Date(value).setSeconds(0)).toISOString() }))
  const localStorage = new CapacitorStorage()

  useEffect(() => {
    if (!show) return;
    initiateProps()

    return () => {
      setFirstAccess(false)
      setInitNewPack(false)
    }
  }, [show])

  const initiateProps = async () => {
    if (pillDate.days > 28) {
      setInitNewPack(true)
      return;
    }
    const { date } = await localStorage.get('date')
    setFirstAccess(date === undefined)
    setInput({ date: date })
    setPackDate(date)
  }

  const submit = async () => {
    await localStorage.create('date', input)
    const pillsDate = numberOfDaysFromInitialDay(input.date)
    await updateStoragePills(input.date, new Date().getTime(), pillsDate)
    setShowModal(false)
  }

  function numberOfDaysFromInitialDay(date: string) {
    const ms = moment(new Date()).diff(moment(date));
    const diff = moment.duration(ms);
    return Math.round(diff.asDays())
  }

  async function updateStoragePills(date: any, currentDate: any, pillDate: any) {
    let child: any = []
    for (let i = 0; i < 28; i++) {
      const cartelaData = new Date(moment(date).add(i, 'days').toISOString()).getTime() as number;
      const alreadyTaken = currentDate > cartelaData;
      child.push({
        takeToday: (pillDate === i) && !alreadyTaken,
        alreadyTaken: alreadyTaken,
        day: i,
        forgotToTake: false,
        date: moment(date).add(i, 'days')
      })
    }

    child = validateTakenPill(currentDate, date, pillDate, child)
    await Promise.all([
      await localStorage.delete('pills'),
      await localStorage.create('pills', child)
    ])
  }


  function validateTakenPill(currentDate = new Date().getTime(), initialDate: string, pillDate: number, pills: any) {
    const cartelaData = new Date(moment(initialDate).add(pillDate, 'days').toISOString()).getTime() as number;
    const alreadyTaken = currentDate > cartelaData;
    if (alreadyTaken) {
      pills[pillDate].takeToday = false;
      pills[pillDate].alreadyTaken = true;
      pills[pillDate + 1].takeToday = true;
    }
    return pills;
  }

  return (
    <IonModal
      isOpen={show}
      cssClass="code-modal"
      backdropDismiss={!(firstAccess || initNewPack)}
      mode="md"
      animated={true}
      onDidDismiss={() => setShowModal(false)}
    >
      <IonText className="header-modal">
        <IonText className="title-modal">Configurações</IonText>
      </IonText>

      <IonItem className="ion-note mb-2" hidden={!initNewPack}>
        <IonLabel className="ion-text-wrap d-flex justify-content-center">
          <p className="ion-note-smaller">Cartela iniciada em <b>{moment(packDate).format('DD/MM/YYYY [às] HH:mm')}</b> acabou! Por favor, Indique a data de início da nova cartela! </p>
          <p></p>
        </IonLabel>
      </IonItem>
      <p className="pack-question h6 text-center">Quando você iniciou a cartela?</p>
      <div className="d-flex justify-content-center">

        <img
          src={arrow}
          alt="Setinha"
          style={{ width: '100px', height: '100px' }}
        />
        <IonItem className="config-items align-self-end">
          <IonListHeader>
            <IonIcon
              mode='ios'
              icon={calendar}
              color={"light"}
              className="m-0 p-3 rounded-circle shadow-sm"
              style={{ backgroundColor: '#ffbebe', fontSize: '2rem' }}
            />
          </IonListHeader>
          <IonDatetime
            mode="ios"
            displayFormat="D MMM YYYY H:mm"
            min={moment(new Date().setHours(0)).subtract(27, 'days').toISOString()}
            max={moment(new Date().setHours(23, 59, 0)).subtract(3, 'hours').toISOString()}
            value={input.date}
            onIonChange={e => onInputChange('date', e.detail.value!)}
            cancelText={"Cancelar"}
            doneText={"Salvar"}
          />
        </IonItem>
      </div>

      <div style={{ display: 'flex' }}>
        <IonButton disabled={!input.date} className="modal-save-button" style={{ width: '50%' }} onClick={() => submit()}>Salvar</IonButton>
        <IonButton disabled={firstAccess || initNewPack} className="modal-close-button " style={{ width: '50%' }} onClick={() => setShowModal(false)}>Fechar</IonButton>
      </div>
    </IonModal>
  );
};


export default ModalComponent