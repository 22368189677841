import { Storage } from '@capacitor/storage'
import { IObject } from './Object';



export interface ICapacitorStorage {
  create(key: string, value: any): Promise<void>;
  get(key: string): Promise<any>;
  getAll(): Promise<any[]>;
  delete(key: string): Promise<void>;
  update(key: string, value: any): Promise<void>;
  find(key: string): Promise<any>;
} 

export class CapacitorStorage implements ICapacitorStorage { 

  
  constructor() {}

  create = async (key: string, value: any) => {
    console.log('create', key, value)
    await Storage.set({
      key: key,
      value: JSON.stringify(value),
    });
  };
  
  get = async (key: string) => {
    const { value } = await Storage.get({ key: key });
    return JSON.parse(value || '{}');
  };

  getAll = async () => {
    const { keys } = await Storage.keys()
    const items = keys.map(async key => await this.get(key))
    return Promise.all(items)
  }

  delete = async (key: string) => {
    console.log('delete', key)
    await Storage.remove({ key: key });
  };

  update = async (key: string, value: any) => {
    await this.delete(key)
    await this.create(value.code, value)
  }

  find = async (key: string) => {
    const all = await this.getAll()
    const pattern = new RegExp(key, 'gi')
    return all.filter(( l: any ) => l.code.match(pattern) || l.title.match(pattern))
  }

}