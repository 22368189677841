import { useState } from "react";
import {
    IonText,
    IonItem,
    IonLabel,
    IonRadioGroup,
    IonListHeader,
    IonRadio,
    IonSegment,
    IonSegmentButton,
  } from '@ionic/react';


const FirstCicleComponent = ({ forgotDays }: any) => {
    const [selected, setSelected] = useState('')
    const forgot = forgotDays.rosePillsForgotten[0]
    return (
      <>
        <div>
          <p className="result-message text-center d-flex align-items-center justify-content-center mb-2" >
            Você deixou de tomar 1 comprimido Rosa Claro (ativo) referente a {forgot.dose}° dose no dia { forgot.date } durante o ciclo do {forgot.cicle.title}!
          </p>
          
          <IonItem className="cicle-recommendations">
            <div hidden={!forgot.greaterThan12hours}>
              <IonListHeader className="mb-2">
                <IonText className="font-weight-bold ion-title-custom ion-list-title question-yaz">
                  Houve relação sexual 7 dias antes ao esquecimento da tomada do comprimido rosa(ativo)?
                </IonText>
              </IonListHeader>
      

              <IonSegment className="segment-yaz" mode="ios" onIonChange={e => setSelected(e.detail.value!)}>
                <IonSegmentButton mode="ios" value="sim">
                  <IonLabel mode="ios" >Sim</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton mode="ios" value="nao">
                  <IonLabel mode="ios">Não</IonLabel>
                </IonSegmentButton>
              </IonSegment>


              <div hidden={selected !== 'sim'}>
                <div className="or-line w-100 mt-2 mb-3" />
                <p className="result-message">
                  Se você esqueceu de tomar um comprimido durante os primeiros 7 dias da sua cartela e teve relações sexuais 7 dias antes ao esquecimento da tomada do comprimido, <b>há possibilidade de engravidar.</b> <i>Neste caso, comunique seu médico antes de iniciar uma nova cartela.</i>
                </p>
              </div>
              <div hidden={selected !== 'nao' }>
                <div className="or-line w-100 mt-2 mb-3" />
                <p className="result-message">
                  <ul>
                    <li>
                      <p><b>Tome o comprimido rosa claro (ativo) que você esqueceu o mais breve possível</b> <i>(inclui-se a possibilidade de tomar dois comprimidos de uma só vez)</i> e continue a tomar os próximos comprimidos no horário habitual.</p>
                    </li>
                    <li>
                      <p>Utilize método contraceptivo adicional durante os próximos 7 dias.</p>
                    </li>
                    <li>
                      <p>Continue tomando todos os comprimidos da cartela até finalizá-la.</p>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </IonItem>
        </div>

      </>
    )
}


const FirstCicle = (forgotDays: any) => <FirstCicleComponent forgotDays={forgotDays} />

  
export default FirstCicle;