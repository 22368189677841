import React, { useEffect, useState } from 'react';
import { IonModal, IonButton, useIonToast, IonContent } from '@ionic/react';

// Sheets
import './pillmodal.css'
import { CapacitorStorage } from '../../entities/Storage';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const ModalComponent = ({ show, setShowModal }: any) => {

  const [cartelaDate, setCartelaDate] = useState('')
  const [calendar, setCalendar] = useState<any>([])
  const [daySelected, setDaySelected] = useState<any>()
  const [hideLabel, setHideLabel] = useState<boolean>(true)
  const [pills, setPills] = useState<any>([])
  const localStorage = new CapacitorStorage()
  const [present] = useIonToast()

  useEffect(() => {
    if (!show) return;
    initiateProps()
  }, [show])

  const initiateProps = async () => {
    const { date } = await localStorage.get('date')
    const pillDate = numberOfDaysFromInitialDay(date)
    setCartelaDate(date)

    // const updatePills = await localStorage.get('updatePills')

    // // if (updatePills) {
    // //   updateStoragePills(date, currentDate, pillDate)
    // //   await localStorage.delete('updatePills')
    // //   return;
    // // }

    const pills = await localStorage.get('pills')

    if (pills) {
      const newPills = validateTakenPill(date, pillDate, pills)
      let calendar = Array.from(new Set(newPills.map((ch: any) => moment(ch.date).format('ddd'))))
      setCalendar(calendar)

      setPills(chunkArrayInGroups(newPills, 7))
      return;
    }

    if (show && pillDate > 28) {
      present({
        message: `Caso já tenha tomado todos os comprimidos brancos, inicie uma nova cartela e atualize a data de inicio da nova cartela clicando na engrenagem no topo da tela.`,
        color: 'danger',
        duration: 10000,
        mode: 'ios',
        position: 'bottom'
      })
    }

  }


  function numberOfDaysFromInitialDay(date: string) {
    const ms = moment(new Date()).diff(moment(date));
    const diff = moment.duration(ms);
    return Math.round(diff.asDays())
  }

  function validateTakenPill(initialDate: string, pillDate: number, pills: any, currentDate = new Date().getTime()) {
    const cartelaData = new Date(moment(initialDate).add(pillDate, 'days').toISOString()).getTime() as number;

    console.log('Pills na posicao PillDate', pills[pillDate])
    for (let i = 0; i < pillDate; i++) {
      pills[i].takeToday = false;
      pills[i].alreadyTaken = true;
    }

    const alreadyTaken = currentDate > cartelaData;
    console.log('Já tomei?', alreadyTaken)
    if (alreadyTaken) {
      pills[pillDate].takeToday = false;
      pills[pillDate].alreadyTaken = true;
      pills[pillDate + 1].takeToday = true;
    } else {
      pills[pillDate].takeToday = true;
    }

    console.log('Todas as Pills:', pills)
    return pills;
  }

  function chunkArrayInGroups(arr: [], size: number) {
    let myArray = [];
    for (let i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i + size));
    }
    return myArray;
  }

  async function forgotToTakeMedicine() {
    const { pos1, pos2 } = daySelected;
    if (!daySelected.pill.alreadyTaken) {
      present({
        message: 'Não é possível adicionar esquecimento na data selecionada!',
        color: 'danger',
        duration: 3000,
        mode: 'ios'
      })
      return;
    }

    const dateForgot = new Date().toISOString()
    const ms = moment(dateForgot).diff(moment(cartelaDate).add(daySelected.pill.day, 'days'));
    const diff = moment.duration(ms);
    const hours = Math.round(diff.asHours())

    pills[pos1][pos2].forgotToTake = true
    pills[pos1][pos2].forgotToTakeDate = dateForgot
    pills[pos1][pos2].greaterThan12hours = hours >= 12

    console.log('piils', pills)
    const arrayPills: any = []
    let whitePills = 0
    let rosePills = 0

    pills.forEach((pill: any) => pill.map((p: any) => {
      arrayPills.push(p)
      if (p.forgotToTake) {
        if (p.day >= 24) whitePills += 1
        if (p.day < 24) rosePills += 1
      }
    }))

    setPills(pills)
    setDaySelected(undefined)
    await localStorage.create('pills', arrayPills)

    console.log(whitePills, rosePills)

    if (rosePills > 1) {
      present({
        message: 'Mais de um comprimido rosa claro (ativo) foi esquecido na cartela atual! Consulte seu médico antes de ter relações. Quanto mais comprimidos sequenciais forem esquecidos, menor será o efeito contraceptivo.',
        color: 'danger',
        duration: 11500,
        mode: 'ios'
      })
      return;
    }

    if (hours >= 12 && daySelected.pill.day < 24) {
      present({
        message: 'Um Comprimido foi esquecido por mais de 12 horas do horário habitual! Por favor, acesse o menu "Esqueceu de Tomar o Yaz®?" na página inicial para obter mais informações de quais ações tomar.',
        color: 'danger',
        duration: 10000,
        mode: 'ios'
      })
      return;
    }

    if (hours < 12 && daySelected.pill.day < 24) {
      present({
        message: 'Houve um atraso de ' + hours + ' horas do horário habitual, a proteção contraceptiva de Yaz® é mantida em até 12 horas. Tome o comprimido que você esqueceu o mais breve possível e tome o próximo comprimido no horário habitual.',
        color: 'warning',
        duration: 10000,
        mode: 'ios'
      })
      return;
    }

    if (daySelected.pill.day >= 24) {
      present({
        message: 'Comprimidos brancos inativos esquecidos podem ser desconsiderados (4 últimos comprimidos da cartela). Todavia, estes comprimidos devem ser descartados a fim de evitar que a fase de ingestão de comprimidos inativos seja prolongada equivocadamente. Saiba mais em "Esqueceu de Tomar o Yaz®?"',
        color: 'primary',
        duration: 10000,
        mode: 'ios'
      })
      return;
    }
  }

  async function undoForgotToTake() {
    const { pos1, pos2 } = daySelected;

    pills[pos1][pos2].forgotToTake = false
    setPills(pills)
    setDaySelected(undefined)

    const arrayPills: any = []
    pills.forEach((pill: any) => pill.map((p: any) => arrayPills.push(p)))

    await localStorage.create('pills', arrayPills)
  }

  return (
    <IonModal
      isOpen={show}
      cssClass="pill-modal"
      mode="md"
      animated={true}
      onDidDismiss={() => setShowModal(false)}
    >
      <IonContent className="no-overflow">
        <div className="container h-100">
          <div className="pills-main">
            <div className="pills-container week-days">
              {calendar.map((date: any) => {
                return (
                  <div className="
                  auto-column
                  d-flex
                  align-items-center
                  justify-content-center
                  p-0
                  "
                  >
                    <p>{date}</p>
                  </div>
                )
              })}
            </div>
            <div className="pill-pack">
              {pills.map((pill: Array<any>, i: number) => {
                return (
                  <div className="pills-container">
                    {pill.map((p: any, j: number) => {
                      const date = moment(p.date).format('DD/MM')
                      // console.log(p.day, '=> ', p)

                      return (
                        <div className="auto-column text-center" onClick={() => setDaySelected({ pill: p, pos1: i, pos2: j })} >
                          <p>{date}</p>
                          <p
                            className={`
                          pill 
                          ${p.day === daySelected?.pill.day ? p.day < 24 ? 'pill-selected' : 'white-pill-selected' : ''} 
                          ${p.day >= 24 ? 'white-pill' : ''}
                          ${p.takeToday ? p.day < 24 ? 'pulse' : 'white-pulse' : ''}
                          ${p.forgotToTake ? p.day < 24 ? 'forget-pill' : 'forget-white-pill' : ''}
                          ${p.alreadyTaken ? 'pill-already-taken' : ''}
                        `}>{p.day + 1}</p>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>

            <div className="pills-container d-flex justify-content-between py-2 container">
              <p className="cartela-inicio"> Cartela iniciada em: <b>{moment(cartelaDate).format('DD/MM/YYYY - HH:mm')}</b></p>
              <p className="cartela-inicio legendas-p" onClick={() => setHideLabel(!hideLabel)} ><p className="legendas-button mr-3">?</p><p>Legendas</p></p>
            </div>

            <div className="legendas container" hidden={hideLabel}>
              {/* <p className="legendas-label">Legendas</p> */}
              <div className="legendas-sub d-flex">
                <div className="d-flex legenda-sub-item">
                  <p className="m-1 legenda-take-today" /><p className="d-flex align-items-center">Dose do Dia</p>
                </div>

                <div className="d-flex legenda-sub-item">
                  <p className="m-1 legenda-active" /><p className="d-flex align-items-center">Selecionado</p>
                </div>

                <div className="d-flex legenda-sub-item">
                  <p className="m-1 legenda-forgot" /><p className="d-flex align-items-center">Esquecido</p>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <IonButton
                disabled={daySelected === undefined}
                className={daySelected?.pill.forgotToTake ? "modal-save-button" : "modal-close-button"}
                style={{ width: '100%' }}
                onClick={daySelected?.pill.forgotToTake ? undoForgotToTake : forgotToTakeMedicine}
              >
                {daySelected?.pill.forgotToTake ? 'Desfazer Marcação' : 'Esqueci de tomar'}
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};


export default ModalComponent