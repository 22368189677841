import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonText,
  IonButtons,
  IonBackButton,
  IonItem,
  IonLabel,
  IonRadioGroup,
  IonListHeader,
  IonRadio,
  IonRow,
  IonCol,
  IonItemGroup
} from '@ionic/react';

// components
import Layout from '../components/shared/layout/layout'
import FirstCicle from '../components/forgot-pills/firstCicle'

import './forgot.css';
import moment from 'moment';
import { CapacitorStorage } from '../entities/Storage';


const CicleHeader = ({forgot}: any) => {

  return (
    <p className="result-message text-center d-flex align-items-center justify-content-center mb-2" >
      Você deixou de tomar 1 comprimido Rosa Claro (ativo) referente a {forgot.dose}° dose no dia { forgot.date } durante o ciclo do {forgot.cicle.title}!
    </p>
  )
}

const SecondCicle = (forgotDays: any) => {
  const forgot = forgotDays.rosePillsForgotten[0]
  return (
    <div>
      <CicleHeader forgot={forgot}/>
      <p className="cicle-recommendations result-message">
        Tome o comprimido que você esqueceu o mais breve possível <b>(inclui-se a possibilidade de tomar dois comprimidos de uma só vez)</b> e continue a tomar os próximos comprimidos no horário habitual.<br/>
        <b>Importante!</b> A proteção contraceptiva de Yaz® está mantida. Não é necessário utilizar outro método contraceptivo adicional.
      </p>
    </div>
  )
}

const ThirdCicle = (forgotDays: any) => {
  const forgot = forgotDays.rosePillsForgotten[0]

  return (
    <div className="mb-2">
      <CicleHeader forgot={forgot}/>
      <p className="result-message text-center mb-3">Escolha uma das duas opções abaixo, sem a necessidade de utilizar outro método contraceptivo adicional.</p>
      <IonItem className="ion-note mb-2" >
        <IonLabel className="ion-text-wrap d-flex justify-content-center">
          <p className="ion-note-smaller">Se não ocorrer sangramento por privação durante a ingestão dos comprimidos brancos (inativos), deve-se considerar a possibilidade de gravidez. Neste caso, consulte seu médico antes de iniciar uma nova cartela.</p>
          <p></p>
        </IonLabel>
      </IonItem>
      <p className="cicle-recommendations result-message">
        <ul>
          <li>
            <p><b>Tome o comprimido rosa claro (ativo) que você esqueceu o mais breve possível</b> <i>(inclui-se a possibilidade de tomar dois comprimidos de uma só vez)</i> e continue a tomar os próximos comprimidos no horário habitual, até o término dos comprimidos rosa claro (ativos), descartando os comprimidos brancos (inativos). </p>
          </li>
          <li>
            <p>Inicie a nova cartela assim que terminar a ingestão dos comprimidos rosa claro (ativos) sem que haja pausa entre uma cartela e outra. </p>
          </li>
          <li>
            <p>É possível que o sangramento ocorra somente após o término dos comprimidos rosa claro (ativos) da segunda cartela. No entanto, pode ocorrer sangramento do tipo gotejamento ou de escape.</p>
          </li>
        </ul>
      </p>
      <div className="d-flex justify-content-between align-items-center my-2">
        <div className="or-line"/>
        <p  className="or-text">ou</p>
        <div className="or-line"/>
      </div>
      <p className="cicle-recommendations result-message">
        <ul>
          <li>
            <p>Deixe de tomar os comprimidos da cartela atual, faça uma pausa de até 4 dias ou menos, contando inclusive o dia no qual esqueceu de tomar o comprimido.</p>
          </li>
          <li>
            <p>Inicie uma nova cartela. </p>
          </li>
        </ul>
      </p>

    </div>
  )
}

const InactiveCicle = ({ whitePills }: any) => {
  const s = whitePills > 1 ? 's' : ''
  const title = whitePills > 0 ? `Você esqueceu de Tomar ${whitePills} Pílula${s} branca${s} (inativa).` : 'Você está no ciclo de Pílulas Inativas (Comprimidos brancos).'
  return (
    <>
    <div>
      <p className="result-message font-weight-bold text-center my-2" >
        {title}
      </p>
    </div>

    <IonItem className="ion-note" hidden={whitePills === 0} >
      <IonLabel className="ion-text-wrap d-flex justify-content-center">
        <p className="ion-note-smaller"><b>Comprimidos brancos inativos esquecidos podem ser desconsiderados (4 últimos comprimidos da cartela).</b> Todavia, estes comprimidos devem ser descartados a fim de evitar que a fase de ingestão de comprimidos inativos seja prolongada equivocadamente.
        </p>
        <p></p>
      </IonLabel>
    </IonItem>

    <p className="cicle-recommendations result-message">
      Durante a ingestão dos comprimidos brancos inativos (cerca de 2 a 3 dias após a ingestão do primeiro comprimido branco inativo), <b>deve ocorrer um sangramento semelhante ao menstrual (sangramento por privação hormonal).</b><br/><br/>

      Inicie nova cartela <b>após o término da ingestão dos comprimidos brancos (inativos)</b>, independentemente de ter cessado ou não o sangramento. Isto significa que, em cada mês, estará sempre iniciando uma nova cartela no mesmo dia da semana e que ocorrerá o sangramento por privação mais ou menos nos mesmos dias da semana.
    </p>
    </>
  )
}


const DefaultRecomendation = () => {
  return (
    <p className="cicle-recommendations result-message">
      <b>Parabéns! Você está tomando todos os comprimidos corretamente e nenhum comprimido foi esquecido!</b><br/>
      Continue tomando os comprimidos no horário habitual!
    </p>
  )
}

const MoreThanOnce = (forgotDays: any) => {
  
  return (
    <div>
      <p className="result-message font-weight-bold text-center my-2">
        Você deixou de tomar <b>{forgotDays.rosePills} Comprimidos Rosa Claro(ativo)</b> nos respectivos dias e ciclos:
      </p>
      <div className="cicle-recommendations mb-3 d-flex flex-column justify-content-center align-items-center">
          <p className="result-message font-weight-bold text-center my-2">
            Doses perdidas:
          </p>
          <div className="container report-items">
              { forgotDays.rosePillsForgotten.map((forgot: any) => {
                return (
                  <div className="row mb-2 card inner-card flex-row">
                    <div className="col-4 p-0">
                      <div className="">
                        <p className="key">Data: {forgot.date}</p>
                        <p className="value">{ forgot.date }</p>
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div className="">
                        <p className="key">Dose:</p>
                        <p className="value"> { forgot.dose }° dose </p>
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div className="">
                        <p className="key">Ciclo:</p>
                        <p className="value"> { forgot.cicle.title } </p>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        <p className="result-message mt-3">
          Você esqueceu de tomar <b>{forgotDays.rosePills} Comprimidos Rosa Claro(ativo)</b> de uma mesma cartela. <br/> <b>Caso não ocorra sangramento por privação hormonal (semelhante à menstruação) cerca de 2 a 3 dias após a ingestão do primeiro comprimido branco(inativos)</b>, <i>pode ser que esteja grávida. Consulte seu médico antes de iniciar uma nova cartela</i>.
        </p>
      </div>
    </div>
  )
}

const OnePill = (forgotDays: any) => {
  const forgot = forgotDays.rosePillsForgotten[0]
  const Recommendation = ciclesRecommendation[forgot.cicle.code](forgotDays)
  return (
    <>
        { forgotDays.rosePillsForgotten[0].greaterThan12hours ? (
          <>
            <div>
              <IonItem className="ion-note mb-2" >
                <IonLabel className="ion-text-wrap d-flex justify-content-center">
                  <p className="ion-note-smaller"><b>Atenção!</b> Ignore este passo caso você já tenha tomado as ações necessárias!</p>
                  <p></p>
                </IonLabel>
              </IonItem>
            </div>
            { Recommendation }
          </>
          ) : (
          <div>
            <p className="result-message text-center mb-3">Você deixou de tomar 1 comprimido Rosa Claro (ativo) referente a {forgot.dose}° dose no dia { forgot.date } durante o ciclo do {forgot.cicle.title}!</p>
            <p className="cicle-recommendations result-message" >
            Não se preocupe, o atraso foi menor que 12 horas do horário habitual, <b>a proteção contraceptiva de Yaz® é mantida.</b><br/>
            Tome o comprimido que você esqueceu o mais breve possível e tome o próximo comprimido no horário habitual.
          </p>
        </div>
        )}
    </>
  )
}

const ciclesRecommendation: any = {
  first: FirstCicle,
  second: SecondCicle,
  third: ThirdCicle,
  moreThanOnce: MoreThanOnce,
  inactivePills: InactiveCicle,
  onePill: OnePill,
  defaultRecomendation: DefaultRecomendation
}


function defineCicle (taken: number) {
  let cicle: { code: string, title: string} = { code: '', title: ''};
  if ( taken >= 1 && taken <= 7 ) cicle = { code: 'first', title: '1° ao 7° dia'} 
  if ( taken >= 8 && taken <= 14 ) cicle = { code: 'second', title: '8° ao 14° dia'} 
  if ( taken >= 15 && taken <= 24) cicle = { code: 'third', title: '15° ao 24° dia'} 
  if (taken > 24) cicle = { code: 'inactivePills', title: 'Pílulas Inativas'}

  return cicle
}

function currentPillDay(cartelaDate: any, pillDate: any) {
  var ms = moment(pillDate).diff(moment(cartelaDate));
  var diff = moment.duration(ms);
  const days = Math.round(diff.asDays())
  const nextDay = pillDate.getTime() > new Date(moment(cartelaDate).add(days, 'days').toISOString()).getTime()

  const taken = nextDay ? Math.round(diff.asDays()) + 2 : days + 1
  return taken
}

const Forgot: React.FC = () => {
  const [cartelaDate, setCartelaDate] = useState<any>()
  const [takenDays, setTakenDays] = useState(0)
  const [forgotDays, setForgotDays] = useState<any>({ whitePills: 0, rosePills: 0,  })
  const [cicle, setCicle] = useState<{ code: string, title: string}>({ code: '', title: ''})
  const [recommendationMessage, setRecommendationMessage] = useState<any>([])
  const localStorage = new CapacitorStorage()


  useEffect(() => {
    initProps()
  }, [])

  useEffect(() => {
    if(!cicle.code.length) return;
    defineRecommendation(cicle)
  }, [cicle])

  async function initProps() {
    const { date } = await localStorage.get('date')
    if (!date) {
      return;
    }
    setCartelaDate(date)
    const taken = currentPillDay(date, new Date())
    await handlePillDate(date, taken)
    setTakenDays(taken)
    setCicle(defineCicle(taken))
  }

  async function handlePillDate(date: any, taken: number) {
    let pills = await localStorage.get('pills')
    const pillDate = numberOfDaysFromInitialDay(date)
    pills = validateTakenPill(date, pillDate, pills)

    let whitePills = 0
    let rosePills = 0
    const rosePillsForgotten:any = []
    pills.forEach((pill: any) => {
      if (pill.forgotToTake) {
        if (pill.day >= 24) whitePills += 1
        if (pill.day < 24) {
          rosePills += 1
          pill.dose = currentPillDay(date, new Date(moment(date).add(pill.day, 'days').toISOString()))
          pill.cicle = defineCicle(pill.dose)
          pill.date = moment(pill.date).format('DD/MM')
          rosePillsForgotten.push(pill)
        }
      }
    })

    setForgotDays({ whitePills: whitePills, rosePills: rosePills, rosePillsForgotten: rosePillsForgotten })
  }

  function validateTakenPill(initialDate: string, pillDate: number, pills: any) {

    const currentDate = new Date().getTime()
    const cartelaData = new Date(moment(initialDate).add(pillDate, 'days').toISOString()).getTime() as number;
    const alreadyTaken = currentDate > cartelaData;
    if (alreadyTaken) {
      pills[pillDate].takeToday = false;
      pills[pillDate].alreadyTaken = true;
      pills[pillDate + 1].takeToday = true;
    }
    return pills;
  }

  function numberOfDaysFromInitialDay(date: string) {
    const ms = moment(new Date()).diff(moment(date));
    const diff = moment.duration(ms);
    return  Math.round(diff.asDays())
  }

  function defineRecommendation(cicle: any) {

    const recommendations: any = []
    if (cicle.code === 'inactivePills') recommendations.push(ciclesRecommendation['inactivePills'](forgotDays))
    
    if(!forgotDays.rosePills && !forgotDays.whitePills) { setRecommendationMessage([ciclesRecommendation['defaultRecomendation'](forgotDays), ...recommendations]); return; }
    if(forgotDays.rosePills > 1) { setRecommendationMessage([ciclesRecommendation['moreThanOnce'](forgotDays), ...recommendations]); return }
    if(forgotDays.rosePills === 1) {
      if (forgotDays.rosePillsForgotten[0].greaterThan12hours) {
        if (cicle.code !== 'inactivePills') {
          recommendations.push(ciclesRecommendation[forgotDays.rosePillsForgotten[0].cicle.code](forgotDays))
        } else {
          recommendations.unshift(ciclesRecommendation['onePill'](forgotDays))
        }
      } else {
        recommendations.push(ciclesRecommendation['onePill'](forgotDays))
      }
    }

    setRecommendationMessage(recommendations);
  }

  return (
    <Layout>
      <IonHeader className="bg-white">
        <IonToolbar className="header-toolbar title-page-header" mode="ios">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" text="" color="light" />
          </IonButtons>
          <IonTitle className="ion-title-custom ion-text-center" >Yaz Helper</IonTitle>
        </IonToolbar>
        <p style={{zIndex: 1000}} className="font-weight-bold ion-title-custom">Esqueceu de Tomar o Yaz®?</p>
        <div className="header-bg"/>
      </IonHeader>
      <IonContent>

        <div className="background-yaz container" style={{ overflow: 'auto', paddingTop: "40px"}}>
          <IonItemGroup className="border-yaz bg-white mb-3">
            <div className="my-3">
              <IonText className=" ion-title-custom w-100">
                <p className="font-weight-bold text-center h5">Relatório Yaz</p>
              </IonText>
            </div>
            <div>
              <div className="container report-items mb-3">
                <div className="row mb-2">
                  <div className="col-6">
                    <div className="card inner-card">
                      <p className="key">Cartela iniciada em:</p>
                      <p className="value">{moment(cartelaDate).format('DD/MM/YYYY - HH:mm')}</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card inner-card">
                      <p className="key">Dose Atual:</p>
                      <p className="value">{takenDays}° Dose - {  moment(cartelaDate).add(takenDays - 1, 'days').format('DD/MM') } </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="card inner-card">
                      <p className="key">Doses Perdidas: </p>
                      <p className="value">{ forgotDays.rosePills + forgotDays.whitePills }</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card inner-card">
                      <p className="key">Ciclo Atual:</p>
                      <p className="value"> { cicle?.title } </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </IonItemGroup>

          <IonItemGroup>
            <div>
              <IonListHeader>
                <IonText className=" ion-title-custom w-100">
                  <p className="font-weight-bold text-center h6 mb-2">{ recommendationMessage.length > 1 ? 'Recomendações' : 'Recomendação' }</p>
                </IonText>
              </IonListHeader>
            </div>
            <div>
              <div className="report-items">
                <div className="row mb-2">
                  <div className="col-12">
                      <>
                        { recommendationMessage.map((recommend: any, i: number) => {
                          return (
                            <>
                              <div className={`${recommendationMessage.length > 1 ? "card mb-4 pb-3" : ''}`}>
                                { recommend }
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3" hidden={recommendationMessage.length === i+1}>
                                <div className="or-line"  />
                                <p className="result-message mx-3 h6">\\</p>
                                <div className="or-line"  />
                              </div>
                            </>
                          )
                        })}
                      </>
                  </div>
                </div>
              </div>
            </div>
          </IonItemGroup>
        </div>
      </IonContent>
    </Layout>
  );
};

export default Forgot;
