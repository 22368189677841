import { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonList,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
  IonButtons,
  useIonViewWillEnter,
  IonRefresherContent,
  IonRefresher,
  useIonToast,
  IonItemGroup,
  IonListHeader,
  IonFab,
  IonFabButton,
  IonRouterLink,
} from '@ionic/react';
import { settings, chevronForwardSharp, calendarOutline } from 'ionicons/icons';

// Assets
import PillsContraceptive from '../assets/icon/contraceptive-pills.png'

// components
import CustomModal from '../components/modal/modal'
import Pills from '../components/pillModal/pillmodal'
import Layout from '../components/shared/layout/layout'

// entities
import { CapacitorStorage } from '../entities/Storage'

import moment from 'moment'
import './Home.css';


const Home: React.FC = () => {
  const [nextDay, setNextDay] = useState<boolean>(false)
  const [isNear, setIsNear] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<any>(false)
  const [showPills, setShowPills] = useState<any>(false)
  const [pillDates, setPillDates] = useState({ days: 0, hours: '00:00' })
  const [cicle, setCicle] = useState<{ code: string, title: string }>()
  const [cartelaDate, setCartelaDate] = useState<any>()
  const [forgotDays, setForgotDays] = useState<{ whitePills: number, rosePills: number }>({ whitePills: 0, rosePills: 0 })
  const localStorage = new CapacitorStorage()
  const [present] = useIonToast()

  useEffect(() => {
    initateProps()
  }, [showModal, showPills])

  useIonViewWillEnter(() => {
    initateProps()
  })

  const refresh = async (e: CustomEvent) => {
    initateProps()
    setTimeout(() => {
      e.detail.complete()
    }, 1000);
  };

  const initateProps = async () => {
    console.log('initiateProps')
    let { date } = await localStorage.get('date')
    setCartelaDate(date)
    const today = new Date();
    if (!date) {
      setShowModal(true)
      return;
    }
    const hours = moment(date).format("HH:mm")
    var ms = moment(today).diff(moment(date));
    var diff = moment.duration(ms);
    const days = Math.round(diff.asDays())

    const nextDay = today.getTime() > new Date(moment(date).add(days, 'days').toISOString()).getTime()
    setNextDay(nextDay)

    const msT = moment(date).add(days, 'days').diff(moment(today))
    const isNear = Math.round(moment.duration(msT).asMinutes())
    setIsNear(Math.sign(isNear) === -1 ? false : isNear <= 1)

    const daysTaken = nextDay ? Math.round(diff.asDays()) + 2 : days + 1

    setPillDates({
      days: daysTaken,
      hours: hours
    })
    defineCicle(daysTaken)

    await handlePillDate(date, daysTaken)

    if (daysTaken > 28) {
      setShowModal(true)
      present({
        message: 'A Cartela atual já acabou! Por favor, adicione o dia que você iniciou a nova Cartela.',
        color: 'danger',
        duration: 11500,
        mode: 'ios'
      })
      return;
    }
  }

  async function updateStoragePills(date: any, pills: any, pillDate: any, currentDate = new Date().getTime()) {
    let child = []
    for (let i = 0; i < pills.length; i++) {
      const cartelaData = new Date(moment(date).add(i, 'days').toISOString()).getTime() as number;
      const alreadyTaken = currentDate > cartelaData;
      child.push({
        ...pills[i],
        takeToday: (pillDate === i) && !alreadyTaken,
        alreadyTaken: alreadyTaken,
        day: i,
        forgotToTake: pills[i].forgotToTake,
      })
    }

  }

  function defineCicle(taken: number) {
    if (taken >= 1 && taken <= 7) { setCicle({ code: 'first', title: '1° ao 7° dia' }); return }
    if (taken >= 8 && taken <= 14) { setCicle({ code: 'second', title: '8° ao 14° dia' }); return }
    if (taken >= 15 && taken <= 24) { setCicle({ code: 'third', title: '15° ao 24° dia' }); return }
    setCicle({ code: 'inative-piils', title: 'Pílulas Inativas' })
  }

  async function handlePillDate(date: any, taken: number) {
    let pills = await localStorage.get('pills')
    const pillDate = numberOfDaysFromInitialDay(date)
    pills = validateTakenPill(date, pillDate, pills)
    updateStoragePills(date, pills, pillDate)

    let whitePills = 0
    let rosePills = 0
    pills.forEach((pill: any) => {
      if (pill.forgotToTake) {
        if (pill.day >= 24) whitePills += 1
        if (pill.day < 24) rosePills += 1
      }
    })

    setForgotDays({ whitePills: whitePills, rosePills: rosePills })
  }

  function numberOfDaysFromInitialDay(date: any,) {
    const ms = moment(new Date()).diff(moment(date));
    const diff = moment.duration(ms);
    return Math.round(diff.asDays())
  }

  function validateTakenPill(initialDate: string, pillDate: number, pills: any) {

    const currentDate = new Date().getTime()
    const cartelaData = new Date(moment(initialDate).add(pillDate, 'days').toISOString()).getTime() as number;
    const alreadyTaken = currentDate > cartelaData;
    if (alreadyTaken) {
      pills[pillDate].takeToday = false;
      pills[pillDate].alreadyTaken = true;
      pills[pillDate + 1].takeToday = true;
    }
    return pills;
  }


  return (
    <Layout>
      <IonHeader>
        <IonToolbar className="header-toolbar title-page-header" mode="ios">
          <IonButtons slot="start" onClick={() => setShowModal(true)}>
            <IonIcon icon={settings} color="light" className="settings-icon header-icon-color" />
          </IonButtons>
          <IonTitle className="ion-title-custom ion-text-center" >Yaz Helper</IonTitle>
        </IonToolbar>
        <div className="header-bg" />
      </IonHeader>
      <IonContent scrollY={false}>
        <div style={{ paddingTop: '60px' }}>
          <IonRefresher slot="fixed" onIonRefresh={refresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          {isNear && (
            <IonItem className="ion-note-alert" >
              <p> O Horário de tomar a Pílula está próximo!</p>
            </IonItem>
          )}
          <IonItem className="ion-note" >
            <IonLabel className="ion-text-wrap" onClick={() => setShowPills(true)}>
              {/* 
              <p> { 
                cicle?.title === "Pílulas Inativas" 
                ? "Você está no ciclo de Pílulas Inativas." 
                : `Você está no ciclo entre o ${cicle?.title}.`} 
              </p> */}
              {/* <p> Você está no {pillDates.days}° dia da sua cartela.</p> */}
              <p> Não esqueça de tomar a pílula {nextDay ? 'amanhã' : 'hoje'} às {pillDates.hours}.</p>
              <p>Visualizar cartela</p>
            </IonLabel>
          </IonItem>
          <div className="container">

            <IonItemGroup className="border-yaz bg-white">
              <div className="my-3">
                <IonText className=" ion-title-custom w-100">
                  <p className="font-weight-bold text-center h5">Relatório Yaz</p>
                </IonText>
              </div>
              <div>
                <div className="container report-items mb-3">
                  <div className="row mb-2">
                    <div className="col-6">
                      <div className="card inner-card">
                        <p className="key">Cartela iniciada em:</p>
                        <p className="value">{moment(cartelaDate).format('DD/MM/YYYY - HH:mm')}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card inner-card">
                        <p className="key">Dose Atual:</p>
                        <p className="value">{pillDates.days}° Dose - {moment(cartelaDate).add(pillDates.days - 1, 'days').format('DD/MM')} </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="card inner-card">
                        <p className="key">Doses Perdidas: </p>
                        <p className="value">{forgotDays.rosePills + forgotDays.whitePills}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card inner-card">
                        <p className="key">Ciclo Atual:</p>
                        <p className="value"> {cicle?.title} </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="list-content py-3">
                <IonRouterLink routerLink={`/esqueci-de-tomar/`} className="option-list-item ">
                  <IonLabel className="ion-text-wrap d-flex align-items-center justify-content-end">
                    <IonText color="primary" className="ion-text-custom text-right pe-2" >
                      Esqueceu de Tomar o Yaz®?
                    </IonText>
                    <IonIcon icon={chevronForwardSharp} className="settings-icon header-icon-color-light" />
                  </IonLabel>
                </IonRouterLink>
              </div>
            </IonItemGroup>
          </div>
          <div className="container mt-3">
            <IonItemGroup className="border-yaz bg-white">
              <div className="my-3 container">
                <IonText className=" ion-title-custom w-100">
                  <p className="font-weight-bold text-left h6">Nota de Atualização</p>
                </IonText>
              </div>
              <div className="container report-items mb-3">
                <p style={{ fontSize: '11px' }} className="font-weight-bold">Correções</p>
                <div className="d-flex align-items-center">
                  <span className="text-color-yaz-light">▸</span>
                  <p className="text-color-yaz-dark mb-0"
                    style={{ fontSize: '12px', lineHeight: '12px' }}>Corrigido bug na Cartela onde a <b>dose atual</b> não piscava sinalizando como ativo.</p>
                </div>
              </div>
              <div className="container report-items mb-3">
                <p style={{ fontSize: '11px' }} className="font-weight-bold">Melhorias</p>
                <div className="d-flex align-items-center">
                  <span className="text-color-yaz-light">▸</span>
                  <p className="text-color-yaz-dark mb-0"
                    style={{ fontSize: '12px', lineHeight: '12px' }}>Agora é possível selecionar todas as horas da sua respectiva data atual no modal de configuração da cartela.</p>
                </div>
              </div>
            </IonItemGroup>
          </div>
        </div>
      </IonContent>
      <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={() => setShowPills(true)}>
        <IonFabButton className="fab-button-package">
          <img src={PillsContraceptive} height="35" width="35" alt="" />
        </IonFabButton>
      </IonFab>

      {
        showModal && (
          <CustomModal show={showModal} setShowModal={setShowModal} pillDate={pillDates} />
        )
      }
      {
        showPills && (
          <Pills show={showPills} setShowModal={setShowPills} />
        )
      }
    </Layout>
  );
};

export default Home;
