import { IonPage } from "@ionic/react";
import React, { ReactNode } from "react";

import './layout.css'
import 'bootstrap/dist/css/bootstrap.min.css'

type Props = {
  children: ReactNode,
};

const Layout = ({ children }: Props) => {

  return (
    <IonPage>
      {children}
    </IonPage>
  );
}

export default Layout;